import IconBanner from 'assets/advertisers/banner.png';
import IconInterstitial from 'assets/advertisers/interstitial.png';
import IconNative from 'assets/advertisers/native.png';
import IconOfferwall from 'assets/advertisers/offerwall.png';
import IconVideo from 'assets/advertisers/video.png';
import cn from 'classnames';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

const ADUnits = () => {
  const { t } = useTranslation();

  const cards = useMemo(
    () => [
      {
        icon: IconInterstitial,
        title: t('advertisers.adUnits.interstitialAds.title'),
        description: t('advertisers.adUnits.interstitialAds.description'),
        size: {
          sm: { x: 62, y: 120 },
          lg: { x: 104, y: 200 },
        },
      },
      {
        icon: IconNative,
        title: t('advertisers.adUnits.nativeAds.title'),
        description: t('advertisers.adUnits.nativeAds.description'),
        size: {
          sm: { x: 78, y: 120 },
          lg: { x: 120, y: 200 },
        },
      },
      {
        icon: IconVideo,
        title: t('advertisers.adUnits.videoAds.title'),
        description: t('advertisers.adUnits.videoAds.description'),
        size: {
          sm: { x: 62, y: 120 },
          lg: { x: 104, y: 200 },
        },
      },
      {
        icon: IconBanner,
        title: t('advertisers.adUnits.bannerAds.title'),
        description: t('advertisers.adUnits.bannerAds.description'),
        size: {
          sm: { x: 78, y: 120 },
          lg: { x: 120, y: 200 },
        },
      },
      {
        icon: IconOfferwall,
        title: t('advertisers.adUnits.offerwall.title'),
        description: t('advertisers.adUnits.offerwall.description'),
        size: {
          sm: { x: 62, y: 120 },
          lg: { x: 104, y: 200 },
        },
      },
    ],
    [t],
  );

  return (
    <section
      className={cn(
        'flex flex-col bg-[#FBFCFD] justify-center items-center',
        'lg:py-[100px] lg:px-0',
        'py-[50px] px-[20px]',
      )}
    >
      <div className="flex flex-col">
        <h6
          className={cn(
            'text-center text-[#2685F4] text-[16px] leading-normal font-semibold',
            'lg:text-[14px] leading-[21px] lg:mb-[4px]',
            'text-[16px] leading-[24px] mb-[2px]',
          )}
        >
          AD Units
        </h6>
        <h3
          className={cn(
            'text-[#212529] text-center font-bold leading-[134%] tracking-[-0.3px] mb-[10px]',
            'lg:text-[36px]',
            'text-[24px]',
          )}
        >
          {t('advertisers.adUnits.title')}
        </h3>
        <p
          className={cn(
            'text-center text-[#808C99] font-normal tracking-[-0.3px] leading-[160%] mb-[30px]',
            'lg:text-[18px]',
            'text-[16px]',
          )}
        >
          {t('advertisers.adUnits.description')}
        </p>
      </div>
      <div
        className={cn(
          'flex justify-start w-full',
          'lg:max-w-[1200px] lg:flex-wrap lg:mt-[30px] lg:flex-row lg:gap-[40px]',
          'flex-col gap-[30px]',
        )}
      >
        {cards.map(({ icon, title, description, size }) => {
          const imgClassName =
            size.lg.x === 120
              ? 'lg:w-[120px] lg:h-[200px] w-[78px] h-[120px]'
              : 'lg:w-[104px] lg:h-[200px] w-[62px] h-[120px]';

          return (
            <div
              key={title}
              className={cn('flex flex-col items-center', 'lg:w-[373px]', 'w-full px-[20px]')}
            >
              <div className={cn('lg:mb-[10px]', 'mb-[6px]')}>
                <img className={imgClassName} src={icon} alt="icon.png" />
              </div>
              <h5
                className={cn(
                  'text-[#212529] font-semibold leading-[150%] tracking-[-0.3px] mb-[4px]',
                  'lg:text-[20px]',
                  'text-[18px]',
                )}
              >
                {title}
              </h5>
              <p
                className={cn(
                  'text-center text-[#808C99] tracking-[-0.3px] font-normal',
                  'lg:text-[16px] lg:leading-[160%]',
                  'text-[14px] leading-[150%]',
                )}
              >
                {description}
              </p>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default ADUnits;
