import { Footer, Header, Starter } from 'components/common';
import { StarterProps } from 'components/common/starter';
import { ReactNode } from 'react';

interface LayoutProps {
  children: ReactNode;
  starterConfig?: StarterProps;
}

const Layout = ({ children, starterConfig }: LayoutProps) => {
  return (
    <>
      <Header />
      <main>{children}</main>
      <Starter
        title={starterConfig?.title}
        description={starterConfig?.description}
        mediaKit={starterConfig?.mediaKit}
        contactHash={starterConfig?.contactHash}
      />
      <Footer />
    </>
  );
};

export default Layout;
