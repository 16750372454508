import { ADUnits, Advertiser, CampaignType, Intro, Technology } from 'components/advertisers';
import { Head } from 'components/common';
import MANUALS from 'constants/manuals';
import Layout from 'layout/index';
import { useTranslation } from 'react-i18next';

const AdvertisersPage = () => {
  const { t } = useTranslation();

  return (
    <Layout starterConfig={{ mediaKit: MANUALS.advertiser }}>
      <Head title="Advertisers" description={t('advertisers.intro.description')} />
      <Intro mediaKit={MANUALS.advertiser} />
      <Advertiser />
      <Technology />
      <CampaignType />
      <ADUnits />
    </Layout>
  );
};

export default AdvertisersPage;
