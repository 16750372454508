import cn from 'classnames';
import { Intro } from 'components/common';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { ImageDataLike } from 'gatsby-plugin-image';
import { useTranslation } from 'react-i18next';

interface AdvertizersIntroProps {
  mediaKit?: string;
}

const AdvertizersIntro = ({ mediaKit }: AdvertizersIntroProps) => {
  const { t } = useTranslation();
  const { backgroundImage } = useStaticQuery<{
    backgroundImage: ImageDataLike;
  }>(
    graphql`
      query AdvertisersPageQuery {
        backgroundImage: file(absolutePath: { regex: "/advertisers/intro.png/" }) {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED)
          }
        }
      }
    `,
  );

  return (
    <Intro backgroundImage={backgroundImage}>
      <h1
        className={cn(
          'text-white font-bold',
          'lg:text-[60px] lg:leading-[124%] lg:mb-[8px]',
          'text-[40px] leading-[110%] mb-[10px] px-[20px]',
        )}
      >
        {t('advertisers.intro.title')}
      </h1>
      <p
        className={cn(
          'text-white tracking-[-0.3px] font-normal',
          'lg:text-[18px] lg:leading-[160%] lg:mb-[30px] lg:whitespace-pre-line',
          'text-[16px] leading-[160%] mb-[20px] px-[20px]',
        )}
      >
        {t('advertisers.intro.description')}
      </p>
      <div
        className={cn(
          'flex flex-wrap justify-center',
          'lg:gap-[12px]',
          'w-full gap-[10px] px-[20px]',
        )}
      >
        <a
          className={cn(
            'flex items-center justify-center text-white tracking-[-0.3px] bg-transparent border border-white rounded-[6px] py-[12px] px-[20px] hover:bg-[rgba(255,255,255)]/10 focus:outline-none',
            'lg:flex-initial lg:py-[12px]',
            'flex-1 py-[14px]',
          )}
          target="_blank"
          href={mediaKit}
        >
          {t('introduction')}
        </a>
        <Link
          className={cn(
            'flex items-center justify-center text-white tracking-[-0.3px] bg-[#2685F4] px-[20px] rounded-[6px] hover:bg-[#2685F4]/80 focus:outline-none',
            'lg:flex-initial lg:py-[12px]',
            'flex-1 py-[14px]',
          )}
          to="/contact#advertisers"
        >
          {t('contact')}
        </Link>
      </div>
    </Intro>
  );
};

export default AdvertizersIntro;
